.broker-info-container {
  margin: 0 auto;
  width: 508px;
  font-weight: normal;
  font-style: normal;

  .header {
    padding-top: 64px;
    font-size: 32px;
    line-height: 32px;
  }

  .body {
    padding-top: 36px;
    padding-bottom: 48px;
    color: rgba(17, 17, 17, 0.75);
    font-size: 14px;
    line-height: 22px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
  }
}
