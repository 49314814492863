$landing_photo: '~broker_snapshots_placeholder_img.jpg';

.estate-agents-welcome {
  overflow-x: hidden;

  .navigation__items {
    right: 25px;
  }

  .hero {
    max-width: 100%;
    max-height: 320px;
    height: 60vh;
    background-image: url($landing_photo);
    background-position: top center !important;
    background-size: contain;
    background-repeat: no-repeat;
    @media screen and (max-width: calc(#{$md} - 1px)) {
      background-size: cover;
      max-height: 180px;
      background-attachment: initial;
      background-position: 63%;
    }

    h4 {
      position: absolute;
      top: 55%;
      left: 50%;
      color: white;
      font-weight: normal;
      font-style: normal;
      font-size: 46px;
      line-height: 46px;
      transform: translate(-50%, -50%);
      @media screen and (max-width: calc(#{$md} - 1px)) {
        font-size: 18px;
        line-height: 18px;
      }
    }
  }

  .icon-item {
    margin: 0 20px;

    .icon-item__icon-container {
      padding: 80px 120px;
      width: 265px;
      border: none;
      border-radius: 6px !important;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

      svg {
        width: 87px;
      }

      .circle {
        display: inline-block;
        padding-right: 0;
        padding-left: 0;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #59277e1a;
      }
    }

    .icon-label__text {
      font-weight: 700;
      font-size: 16px;
      line-height: 56px;
    }

    .icon-item__text {
      margin: 0;
      font-size: 14px;
      @media screen and (max-width: calc(#{$md} - 1px)) {
        margin-bottom: 7px;
      }
    }
  }

  .icons-row {
    margin-top: 60px;
    @media screen and (max-width: calc(#{$md} - 1px)) {
      margin-top: 40px;
    }
    transition: all 0.3s ease-in-out;
  }

  .carousel-slider__container {
    .inner-container {
      width: 100% !important;
    }
  }

  .geolocation-warning {
    margin-top: 20px;
  }

  footer {
    border-top: 0;
  }
}
