//These are repeated components within the whole allabrf project
.page {
  &--white-bg {
    background-color: white;
  }
}

.container-new {
  width: 100%;
  overflow: hidden;

  section {
    margin: 0;
  }
}

.component {
  &__table {
    width: 100%;
    display: table;
    font-size: 14px;
    font-weight: 300;
    color: $gray;
    line-height: 20px;
    @media(min-width: $desktop-breakpoint) {
      font-size: 16px;
      line-height: initial;
    }
  }

  &__body {
    &#{&}--table {
      margin: 0 5px;
      @media(min-width: $desktop-breakpoint) {
        margin: 0;
      }
    }
  }
}

.table {
  &__cell--left {
    padding-left: 8px;
    box-sizing: border-box;
    text-align: left;
  }

  &__row--border {
    border-bottom: 1px solid $grayLighter;

    &:last-child {
      border: 0 !important;
    }
  }

  &__row--borderfull {
    border-bottom: 1px solid $grayLighter;

    a {
      font-weight: 500;
    }
  }

  &__row--top {
    background-color: $lightBlue;
    font-weight: 400;
  }
}

.summarybrowser {
  border-bottom: 1px solid;
  border-bottom-color: $grayLightest;
}

.nolinebrowser {
  .summarybrowser {
    border-bottom: 0 !important;
  }
}

.line {
  border-top: 1px solid $grayLightest;
  border-radius: 2px;
}

.light-blue-block {
  box-sizing: border-box;
  background: $lightBlue;
  border-radius: 4px;
  position: relative;
}

//repeated element
.profile-tool-tip {
  position: absolute;
  background: $purple;
  padding: 7px 5px;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(unquote("100% - -7px"));
  border-radius: 2px;
  color: white;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  display: none;
  line-height: 1.4;
  text-transform: initial;

  &::after {
    content: “”;
    position: absolute;
    border-left: 8px solid rgba(0, 0, 0, 0);
    border-right: 8px solid rgba(0, 0, 0, 0);
    border-top: 8px solid $purple;
    left: 50%;
    transform: translateX(-50%);
    bottom: -8px;
  }
}

.inner-container {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;

  &--narrow {
    max-width: 760px !important;
  }

  @media (max-width: 1040px) {
    width: 90%;
  }
}

.section {
  padding: 60px 0;
  box-sizing: border-box;

  &__heading {
    text-align: center;
    margin: auto;
    display: table;
    width: auto;

    h1,
    h2,
    h3 {
      margin: auto;
      font-size: 2.25em;
      line-height: initial;
    }

    p {
      margin: auto;
      margin-top: 25px;
      max-width: 940px;
      font-size: 1.25rem;
    }

    @media(max-width: 890px) {
      h1,
      h2 {
        max-width: 20em;
        line-height: normal;
      }
    }
    @media(max-width: $mobile-width) {
      padding: 0;
      h1,
      h2 {
        font-size: 1.688em;
        max-width: 15em;
      }
      p {
        font-size: 1em;
        max-width: 20em;
      }
    }

    .h-separator {
      width: 50px;
      margin: 20px auto;
    }
  }

  @media (max-width: $mobile-width) {
    padding: 40px 0;
  }
}

.h-separator {
  width: 100%;
  margin: 10px auto;

  &--w {
    &-10 {
      width: 10%;
    }
  }

  &--h {
    &-1 {
      height: 1px;
    }

    &-2 {
      height: 2px;
    }
  }
}

.v-separator {
  height: 100%;
  margin: 0 auto 10px;
  position: absolute;

  &--w {
    &-1 {
      width: 1px;
    }
  }

  &--h {
    &-40 {
      height: 40%;
    }
  }
}

.new-button {
  display: inline-block;
  width: 100%;
  height: 48px;
  padding: 16px 0;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 150ms ease;
  color: white;
  letter-spacing: 0.5px;
  border: 1px solid transparent;
  max-width: 214px;
  line-height: $init_t_hght;
  cursor: pointer;
}

.search-container {
  max-width: 520px;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(236, 240, 241, 0.9);
  box-shadow: $bg-shadow;
  position: absolute;
  padding: 45px 20px 20px 20px;
  z-index: 2;
  @include center();

  @media(max-width: $mobile-width) {
    width: 85%;
    max-width: initial;
  }

  h4 {
    font-size: 23px;
    margin-bottom: 32px;
  }
}

.alertmark {
  height: 22px;
  margin-top: 2px;
}

.checkmark {
  height: 10px;
  margin-top: 2px;
}

.clearfix {
  clear: both;
}

.failmark {
  height: 10px;
  margin-top: 2px;
}

.finchart {
  float: left;
}

.happy-customers {
  position: absolute;
  top: -50px;
  right: -50px;
  background: $purple;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  transform: rotate(10deg);
  box-shadow: $bg-shadow;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    font-size: 14px;
    color: white;
    text-align: center;
    font-weight: 300;

    strong {
      font-weight: 400;
    }
  }
}

.mapbox {
  background-color: #e5f2cf;
  padding: 0;

  &.map-only {
    .leaflet-control-container,
    .leaflet-control {
      display: none;
    }
  }
}

.nested-page {
  margin-bottom: 5px;
  padding: 20px 0;
  background-color: white;
  padding-bottom: 60px;
  border-bottom: 1px solid;
  border-bottom-color: $grayLighter;
}

.questionmark {
  height: 10px;
  margin-top: 1px;
  opacity: 0.5;
  margin-bottom: -1px;
}

.rating {
  display: inline-block;
  position: relative;
  width: 60px;
  height: auto;
  vertical-align: middle;
  text-align: center;
  line-height: 2;
  border-radius: 50%;
  //badge
  &-badge {
    text-align: center;
    max-width: 150px;
    margin-top: 5px;

    p {
      margin-top: 0 !important;
      padding-top: 0 !important;
      margin-left: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  &__badge {
    display: block;

    img {
      width: 80px;
      height: 80px;
    }
  }

  &__year {
    display: inline-block;
    @media (min-width: $desktop-breakpoint) {
      display: block;
    }

    &--a,
    &--aa,
    &--aaa {
      color: $green;
    }

    &--b {
      color: $yellow;
    }

    &--c {
      color: $red
    }
  }

  &__missing {
    margin: 0 auto;
    margin-bottom: 8px;
    color: white;
    font-weight: 600;
    font-size: 56px;
    line-height: 80px;
    font-family: $lato_font;
    box-sizing: border-box;
    background: $grayLight;
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }

  &--smaller {
    transform: scale(0.85);
  }

  // Rating letter color
  &--a {
    color: $green;

    &::before {
      content: 'A';
    }
  }

  &--aa {
    color: $green;

    &::before {
      content: 'A+';
    }
  }

  &--aaa {
    color: $green;

    &::before {
      content: 'A++';
    }
  }

  &--b {
    color: $yellow;

    &::before {
      content: 'B';
    }
  }

  &--c {
    color: $red;

    &::before {
      content: 'C';
    }
  }

  small {
    font-size: 18px;
  }
}

.scrollTop {
  background: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: 9999;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  bottom: 40px;
  right: 40px;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid $purple;
  }

  @media (max-width: $desktop-breakpoint) {
    display: none !important;
  }
}

.grid-cell {
  position: relative;
  background-color: white;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 10px;
  padding: 25px;
  box-shadow: 1px 1px 1px $grayLight;

  .cell-table {
    margin-bottom: 0;

    th {
      font-size: 12px;
      font-weight: 400;
      color: $grayDark;
    }
  }
}

.forsaljningar__partners {
  text-align: center;
  margin-bottom: 15px;

  span {
    margin-right: 5px;
    font-size: 16px;
    font-weight: 300;
    color: $grayLight;
  }

  img {
    height: 16px;
    width: auto;

    &.bostadsuppg {
      height: 25px;
      vertical-align: middle;
    }
  }
}

#notification {
  position: absolute;
  top: 20%;
  left: 50%;
  z-index: 99;
  visibility: hidden;
  padding: 25px;
  min-width: 240px;
  border-radius: 2px;
  background: $lightBlue;
  color: $gray;
  text-align: center;
  opacity: 0;
  transition: all 0.4s ease-in;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  pointer-events: none;

  @media(max-width: $mobile-width) {
    left: 0;
    right: 0;
    margin: auto;
    max-width: 360px;
  }

  &.display {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(15px);
    -ms-transform: translateY(15px);
    transform: translateY(15px);
  }
}

.caret--down {
  display: inline-block;
  width: 16px;
  height: 6px;
  position: relative;

  &::before, &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    top: 0;
    border-radius: 1px;
    background: $purple;
    transition: all .15s ease;
  }

  &::before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: -3px;
  }

  &::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-left: 3px;
  }
}

a:hover .caret--down {
  &::before,
  &::after {
    background: white;
  }
}

.status__image {
  position: relative;
  cursor: pointer;

  &--verified {
    &::after {
      position: absolute;
      top: 8px;
      left: 36px;
      border-radius: 3px;
      font-size: 14px;
      line-height: 14px;
      background-color: $grayLight;
      color: $gray;
      padding: 8px;
      box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
      opacity: 0;
      content: 'Verifierad av styrelsemedlem';
    }
  }

  &:hover::after {
    opacity: 1;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
}

/* UI switches */
.button-switch {
  position: relative;
  display: inline-block;
  margin-top: -4px;
  width: 50px;
  height: 30px;
  vertical-align: middle;

  input {
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + span {
      background-color: $purple;
    }

    &:checked ~ span:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 34px;
    background-color: $grayLightest;
    cursor: pointer;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      bottom: 3px;
      left: 3px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: white;
      content: '';
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 42px;
    height: 24px;

    span:before {
      bottom: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
    }

    input:checked ~ span:before {
      -webkit-transform: translateX(18px);
      -ms-transform: translateX(18px);
      transform: translateX(18px);
    }
  }
}
