.onboarding_modal {
  .modal-dialog {
    max-width: 960px;
    width: calc(unquote("100vw - 20px"));

    &.embedded {
      max-width: 640px;
    }

    img {
      max-width: 100%;
    }
  }

  &__close-confirmation form {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: auto;
    padding: 20px 0;
    min-width: 340px;
    width: 520px;
    height: 230px;
    border-radius: 4px;
    background: white;

    @media screen and (max-width: $mobile-width) {
      width: 90%;
    }

    i {
      color: $lightBlue;
      font-size: 85px;
    }

    h4 {
      margin-top: 15px;
      font-size: 18px;
    }

    > div {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      background: $lightBlue;

      &.no-background {
        background: transparent;
      }
    }

    p {
      margin: 10px;
    }

    label {
      margin-top: 18px;
    }

    a {
      margin: 10px 0;
      padding: 0 10px;
      width: auto;
      font-size: 14px;

      &:last-of-type {
        margin-right: 10px;
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    padding: 10px 0;
    background: white;

    a {
      margin: 0 10px;
      padding: 0;
      min-width: 120px;
      width: 120px;

      &:first-of-type {
        position: absolute;
        left: 0;
      }
    }

    .counter {
      position: absolute;
      right: 30px;
      height: 40px;
      line-height: 40px;
    }
  }

  &__pagination a,
  &__close-confirmation form a {
    height: 40px;
    line-height: 40px;
  }
}

.feature_modal {
  &__close-confirmation {
    p {
      margin: 10px 0 20px 0;
    }
  }
}
