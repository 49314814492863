@import "mixins.scss";

/*
Reset
*/

html {
  box-sizing: border-box;
  height: 100%;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:focus {
  outline: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

body {
  color: $grayDarker;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 1.5;

  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;

  &.loading_brfdata {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

a {
  color: $purple;
  text-decoration: none;
}

hr {
  border: 0;
  border-bottom: 1px solid $grayLighter;
  @media screen and (min-width: $lg) {
    margin: 24px auto;
  }
}

/*
Global attributes
*/
._wrapper {
  &.-fill {
    display: flex;
  }

  @media screen and (min-width: $lg) {
    position: relative;
    margin: 0 auto;
    padding: 0 24px;
    max-width: 1440px;
  }
}

/*
Shared components
*/
.avatar {
  display: inline-block;
  overflow: hidden;
  max-width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: middle;

  &._sm {
    max-width: 24px;
    height: 24px;
  }

  &._lg {
    max-width: 48px;
    height: 48px;
  }
}

/*
Modifiers
*/

.-hidden {
  display: none;
}

.-reverse {
  order: -1;
}

.-hide-mobile {
  @media screen and (max-width: calc(#{$lg} - 1px)) {
    display: none;
  }
}

.-hide-desktop {
  @media screen and (min-width: $lg) {
    display: none;
  }
}

.-sticky-session {
  margin-bottom: 2%;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.half-side {
  float: left;
  width: 50%;
}

.section--center {
  text-align: center;
}

.line-break {
  display: block;
}

.new-button {
  &--bg {
    border: none !important;
    background-color: green !important;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25) !important;
    color: white !important;

    &:hover {
      background-color: lighten($green, 20%) !important;
    }
  }

  &--frame-white {
    border: 1px solid white;

    &:hover {
      background-color: white;
      color: $grayDarker;
    }
  }

  &--frame-gray {
    border: 1px solid $gray;
    color: $gray;

    &:hover {
      border: 1px solid transparent;
      background: $grayLighter;
      color: white;
    }
  }
}

.rating {
  &--green {
    background-color: $green;
  }

  &--yellow {
    background-color: $yellow;
  }

  &--red {
    background-color: $red;
  }
}

.tr-border-bottom {
  border-bottom: 1px solid $lightBlue;
}

.remove-border-bottom {
  @media (max-width: $mobile-width) {
    border-bottom: 0;
  }
}

.tr-border-left {
  border-left: 1px solid $lightBlue;
}

.tr-border-right {
  border-right: 1px solid $lightBlue;
}

.td--big-font {
  font-size: 1.0625rem !important;
}

.hide-on-mobile {
  @media (max-width: $lg) {
    display: none;
  }
}

.hide {
  animation: hide 1s linear;
  animation-fill-mode: forwards;
}

@keyframes delayedShow {
  to {
    visibility: hidden;
  }
}

.fadeOut {
  visibility: visible;
  animation: 0s linear 0.2s forwards delayedShow;
}

.separator {
  &--green {
    background: $green;
  }

  &--gray {
    background: $grayBlue;
  }

  &--center {
    @include center();
  }
}

.h-separator {
  &--h {
    &-1 {
      height: 1px;
    }

    &-2 {
      height: 2px;
    }
  }
}

.light-blue-block {
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  background: $lightBlue;
}

.break {
  display: none;
}

.no-left {
  left: 0 !important;
}

.pointer {
  cursor: pointer;
}

.purple-link {
  color: $purple !important;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.top {
  align-self: flex-start !important;
}

.full-width {
  max-width: 100% !important;
  width: 100% !important;
}

.half-width {
  width: 50%;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.th--vertical {
  text-align: left !important;
}

.td--vertical {
  text-align: right !important;

  &:first-of-type {
    color: $grayDarkest;
  }

  a {
    font-weight: 500;
  }
}

.always-visible {
  display: block !important;
}

.inline-block {
  display: inline-block;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.margin-auto {
  margin: 0 auto;
}

.margin-10 {
  margin: 10px !important;
}

.margin-t-8 {
  margin-top: 8px !important;
}

.margin-t-16 {
  margin-top: 16px !important;
}

.margin-t-24 {
  margin-top: 24px;
}

.margin-t-20 {
  margin-top: 24px;
}

.margin-mob-t-16 {
  @media (max-width: $lg) {
    margin-top: 16px;
  }
}

.margin-t-32 {
  margin-top: 32px !important;
}

.margin-t-34 {
  margin-top: 34px;
}

.margin-t-48 {
  margin-top: 48px;
}

.margin-t-64 {
  margin-top: 64px !important;
}

.margin-b-0 {
  margin-bottom: 0 !important;
}

.margin-b-8 {
  margin-bottom: 8px;
}

.margin-b-16 {
  margin-bottom: 16px;
}

.margin-b-32 {
  margin-bottom: 32px !important;
}

.margin-b-36 {
  margin-bottom: 36px;
}

.margin-b-48 {
  margin-bottom: 48px;
}

.margin-b-24 {
  margin-bottom: 24px !important;
}

.margin-b-34 {
  margin-top: 48px;
}

.margin-l-8 {
  margin-left: 8px !important;
}

.margin-l-16 {
  margin-left: 16px;
}

.margin-l-24 {
  margin-left: 24px !important;
}

.margin-l-32 {
  margin-left: 32px !important;
}

.margin-l-48 {
  margin-left: 48px !important;
}

.margin-l-76 {
  margin-left: 76px !important;
}

.margin-b-96 {
  margin-bottom: 96px;
}

.margin-l-176 {
  margin-left: 176px;
}

.margin-r-5 {
  margin-right: 5px;
}

.margin-r-8 {
  margin-right: 8px;
}

.no-padding {
  padding: 0 !important;
}

.padding-t-16 {
  padding-top: 16px;
}

.padding-16 {
  padding: 16px !important;
}

.padding-t-40 {
  padding-top: 40px;
}

.padding-t-0 {
  padding-top: 0 !important;
}

.padding-b-0 {
  padding-bottom: 0 !important;
}

.padding-b-5 {
  padding-bottom: 5px;
}

.padding-b-10 {
  padding-bottom: 10px;
}

.padding-b-16 {
  padding-bottom: 16px;
}

.padding-l-8 {
  padding-left: 8px;
}

.padding-l-10 {
  padding-left: 10px;
}

.no-border {
  border: 0 !important;
}

.padding-h-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.padding-b-70 {
  padding-bottom: 70px !important;
}

.gray-filter {
  filter: grayscale(1);
}

.dark {
  color: $grayDarker;
}

.purple {
  color: $purple !important;
}

.bold {
  font-weight: 700;
}

.height-auto {
  height: auto !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-block-spacing {
  margin: 20px 0;
  padding: 20px 10px;

  &-smaller {
    margin: 10px 0;
    padding: 10px;
  }
}

.height-60 {
  box-sizing: border-box;
  height: 60px;
}

.visible {
  display: block;
}

.hidden {
  display: none !important;
}

.footer-filler {
  height: 67px !important;
}

.dot {
  &::after {
    margin-left: -4px;
    content: ".";
  }
}

.flex {
  display: flex;

  &.--row {
    flex-direction: row;
  }

  &.--column {
    flex-direction: column;
  }
}

.flex--center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear {
  clear: both;
}

.error {
  color: $red;
}

.success {
  color: $green;
}

.gray {
  color: $gray;
}

.darkest-gray {
  color: $grayDarker;
}

.callout {
  position: relative;
  margin: 28px 0;
  padding: 15px;
  border: 1px solid $grayLightest;
  border-left: 12px solid $green;
  border-radius: 4px;
  font-size: 14px;
  line-height: 29px;

  &.warning {
    border-left: 12px solid $yellow;
  }

  li {
    padding: 4px 0;
  }
}

.flash-message {
  margin: 28px 0;
  padding: 15px;
  background-color: $green-100;
  border: 1px solid $green-500;
  border-radius: 4px;
  font-size: 16px;
  color: $grayDarkest;

  &.alert, &.failure {
    background-color: $red-100;
    border-color: $red-500;
  }
}

.invalid-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: $red-500;
}
