.broker-container {
  @media screen and (max-width: calc(#{$md} - 1px)) {
    width: 100%;
  }

  position: relative;
  margin: 0 auto;
  padding: 0 15px;
  width: 530px;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    margin: 0;

    -webkit-appearance: none;
  }

  #autoComplete_list {
    margin-top: -16px !important
  }

  .buyer-title {
    position: relative;
    padding: 5px 15px;
    width: 124px;
    height: 32px;
    border-radius: 4px 4px 0 0;
    background: $grayLightest;
  }

  .buyer-items-holder {
    .buyer-item {
      margin-bottom: 15px;

      .buyer-cancel {
        position: absolute;
        top: 50%;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background: $grayLight;
        transition: all 0.3s;
        transform: translateY(-50%);

        &::after,
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2px;
          height: 14px;
          background: $white;
          content: '';
        }

        &::after {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &::before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:hover {
          background: $gray;
          cursor: pointer;
        }
      }
    }

    .fields-holder {
      padding: 15px 12px 0;
      border-radius: 4px;
      background: $grayLightest;
    }

    .price-field {
      margin-bottom: 34px !important;
    }
  }

  .icon-left {
    position: absolute;
    top: 0;
    left: -50px;
    display: inline-block;
    margin: 10px 0;
    width: 16px;
    height: 16px;
    background-image: url($icon_arrow_left);
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    vertical-align: middle;

    @media screen and (max-width: calc(#{$md} - 1px)) {
      left: 13px;
    }
  }

  .title {
    margin-top: 128px;
    margin-bottom: 38px;
    font-weight: normal;
    font-style: normal;
    font-size: 32px;
    line-height: 32px;

    @media screen and (max-width: calc(#{$md} - 1px)) {
      margin-left: 30px;
    }
  }

  .title-description {
    margin-bottom: 38px;
    color: rgba(17, 17, 17, 0.75);
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
  }

  .flash-message {
    position: relative;
    margin: 28px 0;
    padding: 15px;
    border: 1px solid $grayLightest;
    border-left: 12px solid $green;
    border-radius: 4px;
    font-size: 14px;

    &.alert {
      border-left: 12px solid $yellow;
    }

    li {
      padding: 4px 0;
    }
  }

  .order-form,
  .transfer-form {
    .icon-search {
      position: absolute;
      top: 40%;
      right: 15px;
      z-index: 3;
      width: 20px;
      height: 20px;
      background-image: url($icon_search_broker);
      content: '';
      transform: translateY(-50%);
    }

    .field {
      position: relative;

      &.opacity {
        opacity: 0.5;
      }

      input {
        margin-bottom: 16px;
        height: 50px;
      }
    }

    .field-full {
      margin-bottom: 0;
    }

    .field-full,
    .field-half {
      margin-bottom: 15px;
      padding: 13px;
      width: 100%;
      border: 1px solid rgba(204, 204, 204, 1);
      box-shadow: 0 2.5px 10px 0 rgba(0, 0, 0, 0.1);
      font-size: 16px;;
    }

    .field-half {
      width: 49%;
    }

    .full-row {
      margin-right: 30px;
    }

    .questions {
      padding: 16px;
      height: 96px;
    }

    .file-broker {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      width: 100%;
      height: 120px;
      border: 1px dashed rgba(198, 210, 216, 1);
      border-radius: 6px;
      background: $grayLightest;
      color: $gray;
      text-align: center;
      font-size: 14px;
      transition: all 0.1s ease-in-out;

      input[type='file'] {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        margin: 0;
        padding: 0;
        width: 100%;
        outline: none;
        opacity: 0;
        cursor: pointer;
      }

      &:hover {
        border: 2px dashed $purple;
        background: $grayLightest;
      }
    }

    .checkbox-holder {
      display: flex;
      align-items: center;

      .checkbox {
        position: relative;
        margin-right: 10px;
        width: 24px;
        height: 24px;
        opacity: 1;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: 2px solid $purple;
          border-radius: 3px;
          background: transparent;
          content: '';
        }
      }

      .label {
        color: $gray;
        letter-spacing: 0.25px;
        font-size: 13px;
        line-height: 18px;
      }

      .label + a {
        color: $grayDarkest;
        text-decoration: underline;
        font-size: 13px;
      }
    }

    .fields-holder {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .information {
        display: block;
        margin-top: 15px;
        margin-bottom: 14px;
        width: 100%;
        color: rgba(17, 17, 17, 0.75);
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
      }
    }

    .date-picker {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: calc(#{$md} - 1px)) {
        display: block;
        width: 100%;
      }

      .date-holder {
        position: relative;

        @media screen and (max-width: calc(#{$md} - 1px)) {
          margin-bottom: 15px;
        }

        .icon-broker-calendar {
          position: absolute;
          top: 50%;
          right: 15px;
          width: 16px;
          height: 16px;
          background-image: url($icon_broker_calendar);
          background-repeat: no-repeat;
          content: '';
          transform: translateY(-50%);
          @media screen and (max-width: calc(#{$md} - 1px)) {
            right: 0;
            left: 209px;
          }
        }

        input {
          box-sizing: border-box;
          padding-left: 16px;
          width: 244px;
          height: 50px;
          border: 1px solid $grayLight;
          border-radius: 3px;
          background: $white;
          box-shadow: 0 2.5px 10px rgba(0, 0, 0, 0.1);
          text-align: left;
          font-weight: normal;
          font-style: normal;
          font-size: 16px;
          line-height: 50px;
        }
      }
    }

    .cards {
      display: flex;
      justify-content: space-evenly;
      margin: 50px -50px;
      padding: 12px;

      @media screen and (max-width: calc(#{$md} - 1px)) {
        display: block;
        margin: 50px auto;
        width: 100%;
      }

      .card {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 15px;
        width: 32%;
        border-radius: 12px;
        background: $white;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
        text-align: center;

        @media screen and (max-width: calc(#{$md} - 1px)) {
          margin-bottom: 10px;
          width: 100%;
        }

        .body {
          margin-bottom: 15px;
          height: 80px;

          .row {
            color: $grayDarkest;
            text-align: center;
            letter-spacing: 0.25px;
            font-weight: normal;
            font-style: normal;
            font-size: 13px;
            line-height: 22px;
          }
        }

        .head {
          margin-top: 12px;
          margin-bottom: 18px;
          color: $grayDarkest;
          text-align: center;
          font-size: 18px;
          line-height: 18px;
        }

        .circled-amount {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          width: 64px;
          height: 64px;
          border-radius: 100%;
          background: rgba(89, 39, 126, 0.05);
          color: $grayDarkest;
          text-align: center;
          font-size: 14px;
          line-height: 14px;
        }

        .card-button {
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 168px;
          height: 38px;
          border: 1.5px solid $purple;
          border-radius: 6px;
          color: $purple;
          text-align: center;
          letter-spacing: 1px;
          font-weight: bold;
          font-style: normal;
          font-size: 12px;
          line-height: 12px;

          &:hover {
            cursor: pointer;
          }
        }

        &.selected {
          box-sizing: border-box;
          border: 2px solid $purple;
          border-radius: 12px;
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

          .card-button {
            border-radius: 6px;
            background: $purple;
            color: $white;
            text-align: center;
            letter-spacing: 1px;
            font-weight: bold;
            font-style: normal;
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }

    .divider {
      margin-top: 45px;
      margin-bottom: 35px;
      width: 100%;
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      margin: 15px 0 50px;

      .submit {
        width: 200px;
        height: 48px;
        border: none;
        border-radius: 3px;
        background: $green;
        box-shadow: 0 2.5px 10px rgba(60, 196, 102, 0.25);
        color: $white;
        text-align: center;
        letter-spacing: 1px;
        font-weight: bold;
        font-style: normal;
        font-size: 14px;
        line-height: 48px;
      }
    }
  }
}
