.button--primary,
.button--secondary,
.button--tertiary,
.button--danger {
  display: inline-block;
  padding-right: 16px;
  padding-left: 16px;
  min-width: 180px;
  height: 48px;
  border: 0;
  border-radius: 4px;
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 14px;
  line-height: 48px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s, color 0.15s ease-in-out;

  .__lg {
    min-width: 240px;
    height: 60px;
    font-size: 15px;
    line-height: 60px;
  }

  .caret {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 6px;

    &:before,
    &:after {
      position: absolute;
      top: 0;
      width: 10px;
      height: 2px;
      border-radius: 1px;
      content: '';
      transition: all 0.15s ease;
    }

    &:before {
      margin-left: -3px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:after {
      margin-left: 3px;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  &:hover {
    .caret:before,
    .caret:after {
      background: white;
    }
  }
}

.button--primary {
  background: $green;
  color: white;

  &:hover {
    background: darken($green, 10%);
  }
}

.button--primary--small {
  margin-left: 10px;
  width: 100px;
  height: 32px;
  border: 1.5px solid $green;
  border-radius: 4px;
  background: white;
  color: $green;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;

  &:hover {
    background: darken($green, 10%);
    color: white;
    cursor: pointer;
  }
}


.button--secondary {
  border: 2px solid $purple;
  color: $purple;
  line-height: 46px !important;

  .caret:before,
  .caret:after {
    background: $purple;
  }

  &:hover {
    background: $purple;
    color: white;
  }
}

.button--secondary.__inverted {
  border-color: white;
  color: white;

  &.__lg {
    line-height: 58px !important;
  }

  &:hover {
    background-color: white;
    color: $grayDarkest;
  }
}

.button--tertiary {
  background-color: transparent;
  background-color: $grayLightest;
  color: $green;
  font-weight: 700;

  &:hover {
    background: $grayLighter;
  }
}

.button--danger {
  background: $red;
  color: white;

  &:hover {
    background: darken($red, 10%);
  }
}

.button--fold {
  display: block;
  margin: 16px;
  padding: 16px;
  border: 2px solid $grayLighter;
  border-radius: 3px;
  background: white;
  color: $purple;
  text-align: center;
  font-weight: 700;

  &:hover {
    border-color: $grayLight;
  }
}

.button--disabled {
  background-color: $gray;
  cursor: not-allowed;
  pointer-events: none;

  &:hover {
    background-color: $gray;
  }
}

input[class^="button--"]:disabled,
input[class*=" button--"]:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}

@media screen and (min-width: 1024px) {
  .button--fold {
    margin: 0 0 24px;
  }
}

.button--share,
.button--follow {
  position: absolute;
  bottom: 16px;
  display: inline-block;
  padding: 0 16px;
  height: 44px;
  border-radius: 3px;
  background: white;
  color: black;
  line-height: 44px;

  span {
    display: inline-block;
    margin: -4px 8px 0 0;
    width: 16px;
    height: 16px;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
}

.button--share {
  left: 16px;
}

@media screen and (min-width: 1024px) {
  .button--share {
    left: 24px;
  }
}

.button--follow {
  right: 16px;
}

@media screen and (min-width: 1024px) {
  .button--follow {
    right: 24px;
  }
}

.button--share span {
  background-image: url($icon_share);
}

.button--follow span {
  background-image: url($icon_star);
}

.button--follow.__active span {
  background-image: url($icon_star);

  fill: $red;
}

.box a[class^='button--'],
.box button[class^='button--'] {
  margin: 0 auto;
}

/* Needed for the large btns in bankID etc */

.button--fullwidth {
  width: 100%;
}

/* Styles otherwise being overridden by .form-group input[type=submit] */

.popup-module-form .btn-submit input[type='submit'] {
  padding: 0 16px !important;
  background: $green !important;
}

.popup-module-form .btn-submit input[type='submit']:hover {
  background: darken($green, 10%) !important;
}
