#informations-images-modal,
#onboarding-modal {
  .popup-module {
    max-width: 980px !important;
  }
}

#informations-images-modal .popup-module {
  img {
    width: 100%;
  }
}

.popup-module {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin: 0 10px;
  min-width: 320px;
  max-width: 560px;
  border-radius: 4px;
  background: white;
  box-shadow: $bg-shadow;

  @media screen and (max-width: $mobile-width) {
    max-width: calc(unquote("100vw - 20px"));
    max-height: calc(unquote("100vh - 20px"));
  }

  p.extra-margin {
    margin-bottom: 20px;
  }

  .modal-header {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 4px 16px;
    min-height: 48px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: $lightBlue;
    font-size: 16px;
    line-height: 24px;

    &.dark {
      background: $grayDarker;
      color: white;
    }

    p,
    .title {
      position: relative;
      font-weight: 600;
      font-size: 1.18rem;
    }
  }

  &-content {
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 1;
    box-sizing: border-box;
    padding: 16px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background: white;
    line-height: 24px;

    .subheader {
      display: block;
      margin-bottom: 15px;
      color: $gray;
      font-weight: 300;
      font-size: 1rem;
    }

    .after-submit-text {
      h3 {
        font-size: 26px;
      }

      .round-checkmark {
        margin: 20px auto;
      }
    }
  }

  &-form {
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 0;
    width: 100%;
    background: white;

    .form-group {
      position: relative;
      width: 100%;

      &.submit {
        margin-bottom: 0;
        text-align: center;

        &::after {
          display: none;
        }
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #eeeeee;
        content: "";
        transition: all 0.3s ease-in-out;
      }

      label {
        position: absolute;
        bottom: 10px;
        z-index: 2;
        font-size: 1rem;
        transition: all 0.2s ease;
        transform: translateY(0px);
      }

      input[type="text"],
      input[type="email"] {
        position: relative;
        z-index: 3;
        box-sizing: border-box;
        width: 100%;
        border: 0;
        background: none;
        color: $purple;
        font-weight: 300;
        font-size: 1.125rem;
        transition: all 0.2s ease;
      }

      input[type="submit"] {
        margin: auto;
        padding: 20px 10px;
        min-width: 212px;
        color: white;
        font-size: 0.875rem;
        cursor: pointer;

        -webkit-appearance: none;
      }

      &.focus {
        label {
          color: $purple;
          font-size: 0.875rem;
          transform: translateY(-30px);
        }
      }

      &.focus {
        &::after {
          height: 2px;
          background: $purple;
        }
      }

      &.checkbox {
        margin-bottom: 20px;

        &::after {
          display: none;
        }

        input {
          position: absolute;
          left: 0;
        }

        label {
          position: relative;
          bottom: 0;
          z-index: 4;
          padding-left: 3em;
          font-weight: 400;
          cursor: pointer;
        }
      }
    }
  }

  &--button-group {
    overflow: auto;
    margin-top: 20px;
  }
}

.modal__alert {
  margin-top: -10px;
  margin-bottom: 30px;
  color: darken($red, 40%);
}

.modal_checkmark--green {
  color: $green;
  font-size: 60px;

  div {
    margin-top: 20px;
  }

  h3 {
    margin: 30px;
    color: $gray;
    font-size: 20px;
  }
}

.form__group--modal {
  .input-wrapper--half {
    width: 50%;

    label {
      left: -22px !important;
    }

    @media (max-width: $md) {
      width: 97%;
      label {
        left: 5px;
      }
    }
  }

  textarea {
    display: block;
    margin-bottom: 15px;
    width: 100%;
    height: 140px;
    border: 1px solid $grayLighter;
    font-weight: 300;
    font-size: 18px;
  }

  .select__wrapper {
    position: relative;
    width: 100%;

    select {
      display: block;
      margin-bottom: 30px;
      padding-left: 16px;
      width: 100%;
      height: 44px;
      border: 1px solid $grayLighter;
      border-radius: 4px;
      background-color: white;
      font-weight: 300;
      font-size: 18px;

      -webkit-appearance: none;
      -moz-appearance: none;

      &:focus {
        border: 1px solid $purple;
      }
    }

    &::before {
      position: absolute;
      top: 22px;
      right: 16px;
      z-index: 9999999;
      display: block;
      width: 0;
      height: 0;
      border-top: 4px solid $gray;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      content: "";
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  label {
    display: inline-block;
  }

  p {
    font-size: 14px;
  }

  .group--horizontal {
    @media (min-width: $md) {
      display: flex;
      flex-direction: row;

      * + * {
        margin-left: 30px;
      }
    }
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: inherit;
  line-height: 48px;
  cursor: pointer;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}

body.falldown {
  overflow-y: hidden;
}

/* Modals */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);

  &.falldown {
    display: flex;
    animation: modal-open 0.3s;
  }

  h3 {
    margin: 0 auto;
    max-width: 480px;
    font-weight: 700;
  }

  .header {
    position: relative;
    padding: 32px 16px 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
  }

  .body {
    overflow-y: auto;
    padding: 24px;

    .authentication--logo {
      display: block;
      margin: 0 auto;
    }
  }

  .form__group--modal {
    .input-wrapper--half {
      label {
        left: -30px !important;
      }
    }
  }

  .dialog {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 16px auto;
    min-height: calc(100% - 32px);
    max-width: 560px;
    width: calc(100% - 32px);

    p {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .form--border {
      margin-bottom: 24px;
      padding: 16px;
      border: 1px solid $grayLighter;
      border-radius: 3px;
    }

    form {
      border: none !important;
    }

    .content {
      margin: 0 auto;
      width: 100%;
      border-radius: 12px;
      background: white;
      box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    }
  }

  .form-group.text.input-wrapper.no-margin label {
    left: 0;
  }

  .popup-module-form .form-group.checkbox label {
    left: 0;
    padding-left: 32px;
    line-height: 40px;
  }

  .footer {
    position: relative;
    margin-bottom: 20px;
    text-align: center;

    .button--tertiary {
      width: 152px !important;
    }

    .__crosslink {
      display: inline-block;
      text-align: center;
      font-size: 14px;
      line-height: 1.4;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  /* Modal parsley - used with user input errors */
  .parsley-errors-list {
    padding-top: 5px;
  }
}

/* Input wrapper */

.input--wrapper {
  position: relative;

  input {
    display: block;
    margin: 16px auto;
    padding: 22px 12px 4px 12px;
    width: 100%;
    border: 1px solid $grayLight;
    border-radius: 4px;
    color: black;
    font-weight: 300;
    font-size: 16px;

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      margin: 0;

      -webkit-appearance: none;
    }

    &:focus {
      border: 1px solid $purple;
      color: $purple;
    }

    &:disabled ~ label {
      display: none;
    }

    &:focus ~ label,
    &:valid ~ label,
    &.has_value ~ label,
    &:invalid:focus ~ label {
      top: 4px;
      font-size: 14px;
    }
  }

  label {
    position: absolute;
    top: 12px;
    left: 12px !important;
    color: $grayDark;
    font-weight: 300;
    font-size: 16px;
    -webkit-transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    transition: 0.2s ease all;
    pointer-events: none;
  }
}

/* Form elements */
.form--radio {
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;

    &:hover span {
      border-color: $gray;
    }
  }

  input {
    position: absolute;
    visibility: hidden;

    &:checked ~ label {
      color: $grayDarkest;
    }

    &:checked ~ span {
      border-color: $purple;

      &::before {
        background: $purple;
      }
    }
  }

  label {
    position: relative;
    z-index: 9;
    display: inline-block;
    padding-left: 32px;
    height: 40px;
    color: $gray;
    font-size: 16px;
    line-height: 40px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $grayDarkest;
    }
  }

  span {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 5;
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid $gray;
    border-radius: 100%;
    transition: border 0.2s ease-in-out;

    &::before {
      position: absolute;
      top: 2px;
      left: 2px;
      display: block;
      margin: auto;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      content: "";
      transition: background-color 0.2s ease-in-out;
    }
  }
}

/* Modal btns */
.modal,
.local-assigns-modal {
  .button--close-modal {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 !important;
    width: 44px;
    height: 44px;
    border: 0;
    background: transparent;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &::before,
    &::after {
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      background: $gray;
      content: "";
    }

    &::before {
      top: 21px;
      left: 12px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &::after {
      top: 21px;
      right: 12px;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}

@media screen and (max-width: calc(#{$md} - 1px)) {
  .modal#contact-modal,
  .modal#broker-modal {
    .dialog {
      top: 10% !important;
    }
  }
  .modal#invoice-delivery {
    .dialog {
      top: 0 !important;
    }
  }
}

@media screen and (min-width: 375px) {
  .modal#contact-modal,
  .modal#broker-modal {
    .dialog {
      top: 15% !important;
    }
  }
  .modal#invoice-delivery {
    .dialog {
      top: 5% !important;
    }
  }
}

@media screen and (max-width: 375px -1px) {
  .modal#contact-modal,
  .modal#broker-modal {
    .dialog {
      top: 20% !important;
    }
  }
  .modal#invoice-delivery {
    .dialog {
      top: 10% !important;
    }
  }
}

/* For profile pages modals */

.modal {
  .modal-dialog.profile--modal {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 0 10px;
    min-width: 320px;
    max-width: 560px;
    border-radius: 4px;
    background: white;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  }
}

.checkbox {
  position: relative;

  label {
    position: relative;
    display: inline-block;
    padding-left: 23px;
    vertical-align: middle;
    line-height: 16px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      &:before {
        border-color: $purple;
      }
    }

    &:before {
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      border: 2px solid $purple;
      border-radius: 2px;
      background: white;
      transition: border-color 0.2s ease-in-out;
    }

    &:after {
      top: 5px;
      left: 3px;
      width: 10px;
      height: 5px;
      border-bottom: 2px solid white;
      border-left: 2px solid white;
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      position: absolute;
      display: inline-block;
      content: "";
    }
  }

  input[type="checkbox"] {
    position: absolute;
    z-index: 4;
    width: 16px;
    height: 16px;
    opacity: 0;
    cursor: pointer;
  }

  input[type="checkbox"] + label::after {
    content: none;
  }

  input[type="checkbox"]:checked + label::after {
    content: "";
  }

  input[type="checkbox"]:checked + label::before {
    border-color: $purple;
    background: $purple;
  }
}
