#nearest-coops-modal {
  overflow: hidden;

  .list-group > .list-group-item {
    position: relative;
    display: block;
    margin-bottom: -1px;
    padding: 10px 15px;
    border: 1px solid $grayLight;
    border-radius: 0;
    background-color: white;
    color: black;

    &:hover {
      background-color: $lightBlue;
    }
  }

  .infotext {
    padding: 0 30px 10px 30px;
    line-height: 25px;
  }

  .nearest-list {
    margin: 0;
    padding: 0;
  }

  .adress-name {
    display: block;
    margin: 0 0 8px 0;
    padding: 0;
    color: white;
    text-align: left;
    font-size: 14px;
  }

  .name-container {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: calc(unquote("100% - 40px"));
  }

  .name {
    display: block;
    margin: 0;
    width: calc(unquote("100% - 20px"));
    text-align: left;
  }

  .rating-image {
    position: absolute;
    top: calc(unquote("50% - 15px"));
    right: 0;
    width: 65px;
    height: 31px;
    text-align: center;

    .question {
      margin-left: 12px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: white;
      color: white;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
    }
  }

  #show-more {
    display: block;
    padding: 15px 10px;
    text-align: center;
    font-size: 14px;
  }
}
