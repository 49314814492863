@import '../../../../../shared_assets/app/webpack_assets/stylesheets/allabrf_variables.scss'

.management-reports
  font-family: 'Lato'
  font-style: normal
  font-size: 14px
  line-height: 32px
  font-weight: 400


  h3
    font-weight: 600
    font-size: 16px
    line-height: 26px
    color: #111111
    margin-bottom: 5px

  h4
    font-weight: 400
    font-size: 16px
    line-height: 24px
    color: rgba(17, 17, 17, 0.5)
    margin: 12px 0

  h5,
  .h5
    font-weight: 700
    font-size: 14px
    line-height: 24px
    color: #111111
    margin-top: -8px
    margin-bottom: 1px

    & ~ textarea
      margin-top: 12px

  label,
  input:not([type=submit]),
  textarea
    display: block
    clear: both

  label
    color: #111111

  input
    &[type=text],
    &[type=email]
      font-weight: 400
      font-size: 14px
      line-height: 32px
      box-sizing: border-box
      width: 100%
      height: 48px
      border: 1px solid #999999
      padding: 15px
      border-radius: 6px

      &::placeholder
        font-weight: 400
        font-size: 14px
        line-height: 32px
        color: rgba(17, 17, 17, 0.5)

  textarea
    width: 100%
    min-height: 144px
    border: 1px solid #999999
    border-radius: 6px
    padding: 15px
    resize: vertical

  hr
    width: 100%
    color: #E3E3E3
    padding-top: 8px
    margin: 0

  .block
    &-level-0,
    &-level-1,
    &-level-2
      padding: 24px
      width: 100%
      float: left
      border-radius: 6px

    &-level-0
      background: rgba(204, 204, 204, 0.1)
      border: 1px solid rgba(204, 204, 204, 0.5)
      margin-bottom: 32px

    &-level-1
      background: #F4F4F4
      margin-bottom: 16px

      &:last-child
        margin-bottom: 0

  .field-wrapper
    float: left
    margin-top: 8px
    margin-left: 24px

    &.text
      width: 40.3%

      &.full-size
        width: 48%

      &.third-size
        width: 30%

      &.full-size,
      &.third-size
        margin-top: 9px
        margin-bottom: 7px

    &.text-area
      width: 100%
      margin-left: 0

      &.small
        textarea
          min-height: 96px

    &.left-col
      margin-left: 0

  .button-wrapper
    float: left
    margin-top: 25px

  .field_with_errors
    input, textarea
      border: solid 2px $red

  .errors
    margin: 1.5em 0
    color: $red
    font-size: 1rem

    ul
      margin-top: 0.5em
      padding-left: 20px
      counter-reset: item

      li
        list-style-type: disc
        font-weight: 600
        font-size: 0.875rem
        line-height: 1.6

  [data-clonable-remove]
    display: none

  .chapter
    &:last-of-type
      display: none
