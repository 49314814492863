.component--tabs {
  input[name="tabs"],
  input[name="nested-tabs"] {
    display: none;
  }

  .tabs,
  .nested-tabs {
    position: relative;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    white-space: nowrap;

    label {
      position: relative;
      display: inline-block;
      padding: 16px 72px;
      width: auto;
      color: $gray;
      text-align: center;
      font-size: 18px;
      line-height: 16px;
      cursor: pointer;

      @media (max-width: $lg) {
        padding: 16px;
        width: 50%;
      }
    }

    label.radio {
      padding: 16px 28px;
      color: black;

      &:before {
        position: absolute;
        top: 14px;
        left: 0;
        display: block;
        width: 20px;
        height: 20px;
        border: 2px solid $grayLight;
        border-radius: 10px;
        content: "";
      }
    }
  }

  .content {
    margin-top: -2px;
    padding-top: 24px;
    width: 100%;

    &:not(.radio) {
      border-top: 2px solid $grayLightest;
    }

    .tab {
      display: none;
      width: 100%;
    }
  }
}

.export__tabs {
  position: absolute;
  right: 0;
  margin-top: -70px;

  .icon-download {
    display: inline-block;
    margin: 15px 0;
    width: 15.94px;
    height: 16px;
    background-image: url($icon_export_xls);
    background-position: center;
    vertical-align: middle;
    opacity: 55%;

    &:hover {
      opacity: 100%;
    }
  }
}

.toggler--tabs {
  position: relative;
  display: block;
  padding: 0 16px;
  width: 100%;
  height: 44px;
  border: 0;
  background: $lightBlue;
  color: $grayDarkest;
  text-align: left;
  font-size: 16px;
  line-height: 44px;
  cursor: pointer;

  &:after {
    position: absolute;
    top: 50%;
    right: 15px;
    display: block;
    margin-top: 1px;
    border-top: 4px solid $grayDark;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: "";
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: translateY(-50%);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
    pointer-events: none;
  }

  @media screen and (min-width: $lg) {
    display: none;
  }
}

.tabs--content {
  margin: 24px 0;
  border-radius: 3px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s ease-in-out;

  a {
    display: block;
    color: $grayDarker;
  }

  [class^="icon--"] {
    color: $purple;
  }

  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  @media screen and (max-width: calc(#{$lg} - 1px)) {
    &.__active {
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);

      .toggler--tabs {
        &:after {
          margin-top: -1px;
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }

      ul {
        max-height: 500px;
        transition: max-height 0.25s ease-in;
      }
    }

    ul {
      overflow: hidden;
      max-height: 0;
      width: 100%;
      background: white;
      transition: max-height 0.1s ease-in;
    }

    a {
      display: block;
      height: 44px;
      line-height: 44px;
    }

    li {
      text-align: center;
    }

    [class^="icon--"] {
      margin-right: 8px;
      vertical-align: text-bottom;
    }
  }

  @media screen and (min-width: $lg) {
    ul {
      display: flex;
      justify-content: space-between;
    }

    li {
      position: relative;
      flex-grow: 1;
      margin-right: 4px;
      text-align: center;
      cursor: pointer;

      &:last-child {
        margin: 0;
      }

      &.__active a {
        position: relative;
        border-color: $lightBlue;
        background-color: $lightBlue;

        &:before {
          position: absolute;
          bottom: -8px;
          left: 50%;
          border-width: 8px 12px 0;
          border-style: solid;
          border-color: $lightBlue transparent transparent;
          content: "";
          transform: translateX(-50%);
        }
      }
    }

    a {
      height: 66px;
      border: 1px solid $grayLighter;
      border-radius: 2px;
      background-color: white;
      color: $gray;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 700;
      font-size: 11px;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background: $grayLightest;
      }
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      width: 96%;
      word-wrap: break-word;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    [class^="icon--"],
    svg {
      display: block;
      margin: 0 auto 4px auto;
      font-size: 24px;
    }
  }
}
