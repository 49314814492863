.tabulator {
  border: 0;
  border-radius: 4px;
  background: white;
}

.tabulator .tabulator-tableHolder {
  border-top: 0;
  border-right: 1px solid $grayLighter;
  border-bottom: 1px solid $grayLighter;
  border-left: 1px solid $grayLighter;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tabulator .tabulator-table {
  overflow: hidden;
  border: 0;
  color: $grayDarkest;
}

.tabulator .tabulator-header .tabulator-col {
  padding: 0 16px;
  height: 50px;
  background: desaturate(lighten($purple, 20%), 10%);
  color: white;
  line-height: 50px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 0;
}

.tabulator .tabulator-arrow {
  top: 20px !important;
  right: 0;
  border: 0 !important;
}

.tabulator .tabulator-arrow:after {
  display: block;
  width: 8px;
  height: 12px;
  background: url('~icons/icon_sort.svg') 50% 50% no-repeat;
  background-size: 100%;
  content: '';
}

.tabulator .tabulator-header {
  border-bottom: 0;
  background: desaturate(lighten($purple, 20%), 10%);
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 1px solid transparent;
}

.tabulator .tabulator-header .tabulator-frozen-left {
  background: $purple !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow,
.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=none] .tabulator-col-content .tabulator-arrow {
  opacity: 0.5;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=asc] .tabulator-col-content .tabulator-arrow {
  opacity: 1;
}

.tabulator .tabulator-header .tabulator-col {
  border-color: desaturate(lighten($purple, 20%), 10%);
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
  background: desaturate(lighten($purple, 20%), 10%) !important;
}

.tabulator-row {
  height: 40px;
  line-height: 40px;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-left {
  position: relative;
  overflow: visible;
  border-right: 1px solid transparent;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-left:after {
  position: absolute;
  top: 0;
  right: -16px;
  z-index: 99;
  display: block;
  width: 16px;
  height: 40px;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
  content: '';
}

.tabulator-row .tabulator-cell {
  padding: 0 16px;
}

.tabulator-row.tabulator-row-even {
  background: $grayLightest;
}

.tabulator-row.tabulator-selectable:hover {
  background: lighten($grayBlue, 80%);
}

.tabulator-row .tabulator-cell {
  border-right: 1px solid $grayLighter;
}

.tabulator .tabulator-footer {
  margin-top: 12px;
  padding: 0;
  border: 0;
  background: white;
}

.tabulator .tabulator-footer .tabulator-page {
  padding: 0 12px;
  height: 35px;
  font-size: 13px;
  line-height: 35px;
}

.tabulator .tabulator-footer .tabulator-page {
  border: 1px solid $gray;
  background: white;
  color: $grayDarker;
}

.tabulator .tabulator-footer .tabulator-page:not(.disabled):hover {
  background: white;
  color: $grayDarker;
}

.tabulator .tabulator-footer .tabulator-page:not([disabled]):hover {
  border-color: $green;
  background: white;
  color: $grayDarkest;
}

.tabulator .tabulator-footer .tabulator-page.active {
  border-color: $gray;
  background: $gray;
  color: white;
}

.tabulator .tabulator-footer .tabulator-page.active:hover {
  border-color: $gray;
  background: $gray;
  color: white;
}
