// Shared elements done with the BEM methodology (https://en.bem.info/methodology/)

.hero {
  position: relative;
  width: 100%;
  height: 90vh;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.allabrf-img {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.icon-url {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url($icon_link) center center no-repeat;
  background-size: 100%;
  vertical-align: bottom;
}

.green-circle {
  position: relative;
  display: inline-block;
  margin: 0 25px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: $green;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media (max-width: 791px) {
    width: 140px;
    height: 140px;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &__member {
    .allabrf-img {
      height: 55%;
      background-image: url($icon_member);
    }
  }

  &__household {
    .allabrf-img {
      height: 70%;
      background-image: url($icon_household);
    }
  }

  &__chart {
    .allabrf-img {
      height: 80%;
      background-image: url($icon_chart);
    }
  }

  &__pig {
    .allabrf-img {
      height: 80%;
      background-image: url($icon_piggybank);
    }
  }

  > div {
    width: 100%;
    height: 60%;

    @media (min-width: 681px) {
      position: absolute;

      @include center();
    }

    p {
      position: absolute;
      top: 55%;
      left: 50%;
      width: 100%;
      color: white;
      font-size: 2rem;
      line-height: $init_t_hght;
      transform: translate(-50%, -45%);

      span {
        display: block;
        font-size: 1rem;
        line-height: 1.6;
      }

      @media (max-width: 791px) {
        font-size: 1.6rem;

        span {
          font-size: 0.875rem;
        }
      }
    }

    .allabrf-img {
      background-size: contain;
      vertical-align: top;
      opacity: 0.2;

      @media (max-width: $mobile-width) {
        position: absolute;
        top: 45%;
        left: 50%;
        width: 50%;
        height: 40%;
        vertical-align: initial;
        transform: translate(-50%, -55%);
      }
    }
  }

  @media (max-width: 985px) {
    margin: 0 10px;
    width: 160px;
    height: 160px;
  }

  @media (max-width: 740px) {
    width: 140px;
    height: 140px;
  }

  @media (max-width: $mobile-width) {
    display: block;
    margin: 20px auto;
    width: 200px;
    height: 200px;

    &:first-of-type {
      margin-left: auto;
    }

    &:last-of-type {
      margin-right: auto;
    }
  }
}

.consult-team {
  &__item {
    float: left;
    overflow: hidden;
    width: calc(unquote("50% - 19.5px"));
    height: 356px;
    border-radius: 4px;
    background-color: $grayLightest;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
    cursor: default;

    &:nth-child(2n + 2) {
      float: right;
    }

    &:nth-child(-n + 2) {
      margin-bottom: 39px;
    }

    > div {
      float: left;
      overflow: hidden;
      height: 100%;

      &:first-of-type {
        width: 30%;

        @media (max-width: $mobile-width) {
          display: none;
        }
      }

      &:last-of-type {
        width: 70%;

        @media (max-width: $mobile-width) {
          width: 100%;
        }
      }
    }

    .allabrf-img {
      transition: transform 0.3s ease-in-out;
    }

    &:hover {
      > div:first-of-type {
        .allabrf-img {
          transform: scale(1.1);
        }
      }
    }

    @media (max-width: 990px) {
      height: 410px;
    }

    @media (max-width: 790px) {
      float: left !important;
      margin-bottom: 40px !important;
      width: 100%;
    }

    @media (max-width: $mobile-width) {
      height: auto;
    }
  }

  &__heading {
    box-sizing: border-box;
    padding: 25px 15px;
    width: 100%;
    background-color: white;
    line-height: 1.4;

    h4 {
      font-weight: 600;
      font-size: 1.187rem;
    }

    h5 {
      color: $gray;
      font-size: 1.06rem;
    }

    @media (max-width: $mobile-width) {
      position: relative;
      padding: 0;
      height: 140px;

      > span {
        position: absolute;
        top: 50%;
        right: 0;
        float: left;
        box-sizing: border-box;
        padding-left: 20px;
        width: calc(unquote("100% - 140px"));
        transform: translateY(-50%);
      }
    }

    .mobile-img {
      display: none;

      @media (max-width: $mobile-width) {
        display: block;
        float: left;
        width: 140px;
        height: 140px;

        .allabrf-img {
          background-position: center 18%;
          background-size: 160%;

          &.fredrik {
            background-size: 180%;
          }
        }
      }
    }
  }

  &__article {
    box-sizing: border-box;
    padding: 25px 15px;
    width: 100%;
    color: $grayDarker;
    font-size: 0.875rem;
    line-height: 1.6;

    ul {
      margin-left: 1.2em;

      @media (max-width: 790px) {
        font-size: 1rem;
      }

      li {
        margin-bottom: 5px;
        list-style-type: disc;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.range-item {
  margin-top: 1.5em;
  width: 100%;

  &__content {
    width: 100%;
    height: 200px;
    background: rgba(74, 144, 226, 0.05);

    @media (max-width: $mobile-width) {
      box-sizing: border-box;
      padding: 40px 0;
      height: 360px;
    }
  }

  &__div {
    position: relative;
    top: 50%;
    display: inline-block;
    width: 27.1%;
    vertical-align: top;
    transform: translateY(-50%);

    @media (max-width: $mobile-width) {
      position: initial;
      top: initial;
      vertical-align: initial;
      transform: none;
    }

    &.ring-for-offer {
      width: 40.4%;
      line-height: 1.2;

      @media (max-width: $mobile-width) {
        margin-top: 5%;
        width: 100%;
      }

      span,
      a {
        display: block;
      }

      a {
        color: $purple;
        font-size: 2.25rem;

        &:hover {
          text-decoration: underline;
        }
      }

      span {
        color: $gray;
        font-size: 1rem;
      }
    }
  }

  &__slider {
    margin-right: 2em;

    @media (max-width: $mobile-width) {
      margin-bottom: 30px;
      width: 70%;
    }

    > span {
      display: block;
      margin-bottom: 1em;
      color: $gray;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  &__input-span {
    span {
      color: $gray;
      font-weight: 600;
      font-size: 1.5rem;

      &:first-of-type {
        float: left;
        margin-left: 12px;
      }

      &:last-of-type {
        float: right;
      }
    }
  }

  &__input {
    position: relative;
    display: block;
    margin-top: 0.5em;
    width: 100%;

    input[type=range] {
      width: 80%;
      height: 4px;
      outline: none;
      border-radius: 2px;
      background: $grayLight;

      -webkit-appearance: none;

      &::-webkit-slider-thumb {
        width: 16px;
        height: 16px;
        outline: none;
        border-radius: 50%;
        background: $green;
        cursor: pointer;

        -webkit-appearance: none;
      }

      &::-moz-range-thumb {
        width: 16px;
        height: 16px;
        outline: none;
        border-radius: 50%;
        background: $green;
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__output {
    text-align: center;

    output {
      display: block;
      margin-top: 1rem;
      color: $gray;
      font-size: 1rem;

      &::after {
        display: inline-block;
        margin-left: 5px;
        content: 'st';
      }
    }
  }

  &__result {
    width: 20%;
    line-height: 1.2;

    @media (max-width: 810px) {
      width: 25%;
    }

    @media (max-width: 590px) {
      width: 45%;
      &:last-of-type {
        margin-top: 15px;
      }
    }

    span {
      display: block;

      &:first-of-type {
        color: $purple;
        font-size: 2.25rem;

        &::after {
          display: inline-block;
          margin-left: 10px;
          content: 'kr';
        }
      }

      &:last-of-type {
        color: $gray;
        font-size: 1rem;
      }
    }
  }

  &__footer {
    padding: 30px 0;
    width: 100%;
    background: rgba(74, 144, 226, 0.1);

    @media (max-width: $mobile-width) {
      box-sizing: border-box;
      padding: 30px;
    }

    span {
      display: block;
      margin: auto;
      max-width: 700px;
      width: 100%;
      color: $grayDarker;
      font-size: 1rem;
      line-height: $norm_t_hght;
    }
  }
}

.tabs__container {
  position: relative;
}

.tabs__inner-wrapper {
  a {
    display: inline-block;
    box-sizing: border-box;
    padding: 15px 30px;
    height: 60px;
    border: 2px solid $grayLightest;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    background: transparent;
    font-size: 19px;
    cursor: pointer;

    &.active {
      background: $purple;
      color: white;
    }

    &:nth-child(2) {
      margin: 0 77px;
    }

    @media(max-width: 905px) {
      &:nth-child(2) {
        margin: 0 30px;
      }
    }

    @media(max-width: 905px) {
      padding: 15px;
      &:nth-child(2) {
        margin: 0 15px;
      }
    }
  }
}

.icon-item {
  display: inline-block;
  max-width: 320px;
  width: 100%;
  vertical-align: top;

  &__icon-container {
    position: relative;
    margin: auto;
    width: 100px;
    height: 100px;
    border: 1px solid desaturate(lighten($purple, 20%), 10%);
    border-radius: 50%;
    background: white;
  }

  &__icon {
    position: absolute;
    width: 40px;

    @include center();

    g g {
      fill: $purple;
    }
  }

  &__text {
    margin-top: 20px;
    color: $gray;
    font-size: 1.188em;
  }

  &--disabled {
    svg g g {
      fill: $gray;
    }

    span {
      display: block;
      color: $grayLight;
    }
  }
}

.button {
  &--default {
    background-color: $green;
    color: white;

    &:hover {
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) $green;
    }
  }

  &--alert {
    background-color: $red;

    &:hover {
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) $red;
    }
  }

  &--border {
    border: 2px solid $purple;
    box-shadow: none !important;
    color: $purple;

    &:hover {
      background-color: $purple;
      color: white;
    }
  }

  &--borderalt {
    padding-top: 15px;
    border: 2px solid $green;
    box-shadow: none !important;
    color: $green;

    &:hover {
      background-color: $green;
      color: white;
    }
  }

  &--full {
    width: 100%;
  }
}

.cross-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 0 0 0 4px;
  background: $lightBlue;
}

[data-tooltip-toggle]:hover ~ .item-tooltip {
  position: absolute;
  z-index: 9999;
  display: block;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.modal .item-tooltip {
  right: 0;
  left: 0;
  margin: auto;
  width: 80%;
}

.item-tooltip  {
  top: 40px;
  left: 0;
  display: none;
  background: white;
  white-space: initial;
  line-height: 1.6;

  &::before {
    position: absolute;
    top: 0;
    left: 47%;
    margin-top: -5px;
    width: 0;
    height: 0;
    border-right: 10px solid rgba(0, 0, 0, 0);
    border-bottom: 5px solid white;
    border-left: 10px solid rgba(0, 0, 0, 0);
    content: '';
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  ul {
    margin: 15px 0;
    list-style: disc inside;
  }

  p {
    margin: 0;
  }

  p,
  ul {
    padding: 0;
    font-weight: 300 !important;
    font-size: 14px !important;
  }
}

@media (max-width: $mobile-width) {
  .tabs {
    &__container {
      top: 0;
      z-index: 9987;
      overflow: hidden;
      margin: auto;
      padding-bottom: 2px;
      width: 100%;
      height: 38px;
    }

    &__inner-wrapper {
      position: relative;
      top: initial;
      z-index: 1;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      padding: 0 30px 50px 30px;
      height: 100%;
      white-space: nowrap;

      a {
        padding: 0;
        padding-bottom: 10px;
        height: auto;
        border: none;
        border-bottom: 2px solid transparent;
        color: $purple !important;
        transition: all 0.2s ease-in-out;

        &.active {
          border-bottom: 2px solid $purple;
          background: transparent;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}

.section {
  &__carousel-slider {
    .half-side {
      position: relative;
      @media (max-width: $mobile-width) {
        order: 2;
        width: 100%;
        .allabrf-img {
          height: 320px;
        }
      }
    }

    article {
      width: 85%;

      h4 {
        margin-bottom: 30px;
        font-size: 1.875rem;
        line-height: 1;
      }

      p {
        color: $gray;
        font-weight: 300;
        font-size: 1.25rem;
      }

      @media (min-width: 681px) {
        position: absolute;

        @include center();
      }

      @media (max-width: $mobile-width) {
        margin: 0 auto;
        padding: 30px 0;
        h4 {
          margin-bottom: 20px;
          font-size: 1.5rem;
        }

        p {
          font-size: 1rem;
        }
      }
    }
  }

  &__carousel-slider {
    .slick-list {
      padding-bottom: 100px !important;
    }

    .slick-track {
      padding: 0 0 35px 0;

      .slick-slide {
        margin: 0 20px;

        &[aria-hidden='false'] {
          .slide-div {
            box-shadow: $bg-shadow;
          }
        }
      }
    }

    .slick-prev {
      @media (max-width: 1160px) {
        left: 0;
      }
    }

    .slick-next {
      @media (max-width: 1160px) {
        right: 0;
      }
    }

    .slick-arrow {
      top: 32.8%;
      transform: translateY(-60%);
      @media (max-width: 768px) {
        display: none !important;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: 5rem;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
    }
  }

  //LANDING PAGE
  &__tabs {
    position: relative;
    overflow: hidden;
    padding: 0;
    text-align: center;
    @media(max-width: $pad-width) {
      max-height: initial;
    }
  }
}

.tabs-carousel {
  display: flex;
  justify-content: space-between;
  width: 300%; // each slide multiplies width by 100
  background-color: $grayLightest;

  &__slide {
    position: relative;
    padding: 40px 0 60px 0;
    width: 100%;
    background-color: $grayLightest;
    opacity: 1;
    transition: all 0.8s ease-in-out;
    transform: translate3d(0, 0, 0);
    @media(max-width: $mobile-width) {
      margin: auto;
      width: 90% !important;
    }

    .img-container {
      position: relative;
      margin: auto;
      max-width: 565px;
      width: 100%;
      height: 350px;
      @media(max-width: $mobile-width) {
        max-height: initial;
        height: auto;
      }
    }

    .allabrf-img {
      background-size: contain;
      @media(max-width: $mobile-width) {
        height: 300px;
      }
    }
  }
}

.landing-block {
  position: relative;
  margin: 100px auto 0;
  padding: 25px;
  max-width: 960px;
  width: 100%;
  text-align: center;

  &.offer {
    &::before {
      position: absolute;
      top: -17.5px;
      box-sizing: border-box;
      padding: 10px 30px;
      height: 35px;
      border-radius: 4px;
      background: $gray;
      color: white;
      content: 'Erbjudande';
      font-size: 14px;
      line-height: $init_t_hght;

      @include centerX();
    }

    .landing-block__item {
      display: inline-block;
      width: 48%;
      vertical-align: top;
      @media(max-width: $mobile-width) {
        display: block;
        width: 100%;
      }
    }
  }

  &__img {
    margin: auto;
    height: 60px;
  }

  &__item {
    display: block;
    width: 100%;
    height: auto;
  }

  &__title {
    color: $grayDarkest;
    font-size: 1.438em;
    @media(max-width: $mobile-width) {
      span {
        display: block;
      }
    }
  }

  &__text {
    display: block;
    margin: 10px 0;
    min-height: 40px;
    font-size: 1em;
  }

  &__link {
    position: relative;
    display: inline-block;
    color: $grayDarkest;
    font-size: 0.875em;
  }

  &__icon {
    height: 8px;
    vertical-align: middle;
  }

  .new-button {
    display: block;
    margin: 25px auto 0;
    box-shadow: none;
  }

  @media(max-width: 1030px) {
    width: 90%;
  }

  @media(max-width: $mobile-width) {
    padding: 25px 0;
    width: 100%;
  }
}

.media {
  &__text {
    color: $gray;
    font-size: 1.188em;
  }

  &__items {
    margin-top: 30px;
  }

  &__img {
    display: inline-block;
    margin: 0 15px;
    height: 50px;
    vertical-align: middle;
    opacity: 0.7;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.expressen {
      margin-top: 10px;
    }

    &.aftonbladet {
      opacity: 0.5;
    }
  }

  @media(max-width: 1470px) {
    &__img {
      margin: 0 10px;
      height: 40px;
    }
  }

  @media(max-width: 1140px) {
    .break--pad {
      display: block;
    }

    &__img {
      margin: 0 15px;

      &:nth-child(-n+5) {
        margin-bottom: 30px;
      }

      &.expressen {
        margin-right: 0;
      }

      &.aftonbladet {
        margin-left: 0;
      }
    }
  }
  @media(max-width: $mobile-width) {
    .break--pad {
      display: none;
    }

    .break--mob {
      display: block;
    }

    &__img {
      &:nth-child(-n+5) {
        margin-bottom: 0;
      }

      &:nth-child(-n+3) {
        margin-bottom: 30px;
      }

      &:nth-child(-n+3) {
        margin-bottom: 30px;
      }

      &.expressen,
      &.aftonbladet {
        margin-bottom: 30px;
      }
    }
  }
}

.input-wrapper {
  position: relative;
  margin-right: 18px;

  input {
    display: block;
    margin-bottom: 30px;
    padding: 8px 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid $grayLighter;
    color: $gray;
    font-weight: 300;
    font-size: 16px;

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      margin: 0;

      -webkit-appearance: none;
    }

    &[type='text'] ~ label {
      left: 6px;
    }

    &:focus {
      border-bottom: 1px solid $purple;
      color: $purple;
    }

    &:required {
      box-shadow: none;
    }

    &:disabled ~ label {
      display: none;
    }

    &:focus ~ label,
    &:valid ~ label,
    &.has_value ~ label,
    &:invalid:focus ~ label {
      top: -20px;
      font-size: 14px;
    }
  }

  label {
    position: absolute;
    top: 10px;
    color: $gray;
    font-weight: 300;
    font-size: 18px;
    -webkit-transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    transition: 0.2s ease all;
    pointer-events: none;
  }

  h2 {
    margin-bottom: 50px;
    text-align: center;

    small {
      display: block;
      color: $gray;
      font-weight: normal;
    }
  }
}

.content__brfanalysis-body {
  span.text-parenthesis {
    color: $gray;
    font-size: 17px;
  }

  .text_container {
    @media(min-width: $desktop-breakpoint) {
      margin-right: 150px;
      margin-left: 150px;
    }
  }
}
