.slick-slide,
.slick-current,
.slick-cloned {
  outline: 0;
}

.slide-pane:focus {
  outline: none;
}

.organization-slider {
  .slick-list {
    overflow: hidden;
  }

  .slick-slide {
    margin: 0 20px;
    width: 197px;
    height: 250px;

    .slide-div {
      height: 250px;

      a {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        width: 100%;
        height: 100%;

        &.show-a {
          display: block;
        }
      }
    }

    .slide-img-container {
      position: relative;
      width: 100%;
      height: calc(unquote("100% - 62px"));

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        content: '';
      }

      .slide-image {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .slide-heading {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        color: white;
        text-align: center;
        transform: translate(-50%, -50%);
      }
    }

    .slide-text {
      position: relative;
      box-sizing: border-box;
      padding: 10px;
      width: 100%;
      height: 62px;
      background: white;
      white-space: nowrap;

      .slide-text-ul {
        overflow: hidden;
        width: 80%;
        font-size: 9px;

        ul {
          display: inline-block;
          margin: 0;
          padding: 0;
          color: black;
          line-height: 1.5;

          &:first-of-type {
            margin-right: 5px;
            color: darken($grayBlue, 20%);
          }

          li {
            list-style: none;
          }
        }
      }

      .rating-badge {
        position: absolute;
        top: 50%;
        right: 8px;
        margin: 0 !important;
        width: 20%;
        text-align: center;
        font-size: 9px;
        transform: translateY(-50%);

        img {
          margin: auto;
        }

        &.missing-rating {
          .fa {
            color: black;
          }
        }

        .rating-a,
        .rating-aa,
        .rating-aaa {
          color: $green;
        }

        .rating-b {
          color: $yellow;
        }

        .rating-c {
          color: $red;
        }
      }
    }
  }
}

.with-new-arrows {
  .slick-arrow {
    position: absolute;
    top: 50%;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $grayLight;
    font-size: 0;
    cursor: pointer;
    transform: translateY(-50%);

    &:hover {
      background-color: $gray;
    }
  }

  .slick-prev {
    left: -60px;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      margin-top: -4px;
      margin-left: -1px;
      width: 12px;
      height: 2px;
      border-radius: 2px;
      background-color: white;
      content: '';
      /* this single pixel is actually very important. */
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      margin-top: 4px;
      margin-left: -1px;
      width: 12px;
      height: 2px;
      border-radius: 2px;
      background-color: white;
      content: '';
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }

  .slick-next {
    right: -60px;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      margin-top: -4px;
      margin-left: 1px;
      width: 12px;
      height: 2px;
      border-radius: 2px;
      background-color: white;
      content: '';
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      margin-top: 4px;
      margin-left: 1px;
      width: 12px;
      height: 2px;
      border-radius: 2px;
      background-color: white;
      content: '';
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }
  }
}

.aboutus__quotes {
  width: 100%;

  .aboutus__slider {
    background-color: white;

    .slick-slide {
      height: auto;
    }

    .slider__content {
      position: relative;
      display: block;
      box-sizing: border-box;
      margin: 50px 20px;
      padding: 40px 20px;
      max-width: 560px;
      background-color: white;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
      @media (min-width: 600px) {
        margin: 50px auto;
      }
      @media (min-width: 768px) {
        height: 240px;
      }

      p {
        color: $gray;
        text-align: left;
        letter-spacing: 1px;
        font-weight: 300;
        font-style: italic;
        font-size: 16px;
        font-family: $lato_font;
        line-height: 24px;

        &::before {
          display: inline-block;
          margin-right: 15px;
          margin-bottom: 5px;
          width: 25px;
          height: 20px;
          background-image: url($icon_quote);
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          content: '';
        }

        &::after {
          display: block;
          margin: 10px 0;
          border-bottom: 1px solid $gray;
          content: '';
          opacity: 0.25;
        }
      }

      span {
        float: right;
        color: $grayDarker;
        text-align: right;
        font-weight: 400;
        font-size: 16px;
        font-family: $lato_font;
      }

      &::after {
        position: absolute;
        bottom: -20px;
        left: 100px;
        width: 0;
        height: 0;
        border-width: 0 30px 20px 0;
        border-style: solid;
        border-color: transparent white transparent transparent;
        content: '';
      }
    }
  }
}

.slick-dots {
  text-align: center;

  li {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 5px;
    width: 10px;
    height: 10px;
    border: 2px solid transparent;
    border-radius: 50%;
    background: $grayLight;
    font-size: 0;
    cursor: pointer;

    &.slick-active {
      border: 2px solid $gray;
      background: white;
    }

    button {
      display: none;
    }
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  .slick-track,
  .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-track {
  position: relative;
  top: 0;
  display: block;

  &:before {
    display: table;
    content: '';
  }

  &:after {
    display: table;
    clear: both;
    content: '';
  }
}

.slick-loading .slick-track {
  visibility: hidden;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  opacity: 0.5;
  transition: all 0.25s ease-in-out;

  user-select: none;
  touch-action: pan-y;

  &.slick-current {
    opacity: 1 !important;
  }

  &[aria-hidden='false'] {
    @media (min-width: 700px) {
      opacity: 1 !important;
    }
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

@media (max-width: 680px) {
  .premium-slider-section-content {
    .slick-list {
      padding-bottom: 100px !important;
    }

    .slick-dots {
      position: absolute;
      bottom: 5rem;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
    }
  }

  .aboutus__quotes .aboutus__slider .slider__content span {
    font-size: 0.875rem;
  }
}
